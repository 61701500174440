@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Satoshi Black";
  src: url("./assets/fonts/Satoshi Black.otf");
}

@font-face {
  font-family: "Satoshi BlackItalic";
  src: url("./assets/fonts/Satoshi BlackItalic.otf");
}

@font-face {
  font-family: "Satoshi Regular";
  src: url("./assets/fonts/Satoshi Regular.otf");
}

@font-face {
  font-family: "Satoshi Bold";
  src: url("./assets/fonts/Satoshi Bold.otf");
}

@font-face {
  font-family: "Satoshi BoldItalic";
  src: url("./assets/fonts/Satoshi BoldItalic.otf");
}

@font-face {
  font-family: "Satoshi Italic";
  src: url("./assets/fonts/Satoshi Italic.otf");
}

@font-face {
  font-family: "Satoshi Light";
  src: url("./assets/fonts/Satoshi Light.otf");
}

@font-face {
  font-family: "Satoshi LightItalic";
  src: url("./assets/fonts/Satoshi LightItalic.otf");
}

@font-face {
  font-family: "Satoshi Medium";
  src: url("./assets/fonts/Satoshi Medium.otf");
}

@font-face {
  font-family: "Satoshi MediumItalic";
  src: url("./assets/fonts/Satoshi MediumItalic.otf");
}

/* Satoshi Black
Satoshi BlackItalic
Satoshi Regular
Satoshi Bold
Satoshi BoldItalic
Satoshi Italic
Satoshi Light
Satoshi LightItalic
Satoshi Medium
Satoshi MediumItalic */

.bubbleUI {
  position: relative;
  background-color: rgba(0, 0, 0, 0) !important;
  width: 100%;
  /* max-width: 1000px; */
  height: 100vh;
  border-radius: 50px;
  background-color: white !important;
  overflow: hidden !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  /* margin-bottom: 10px; */
}

body {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

input[type="range"]::-webkit-slider-thumb {
  opacity: 0;
}
input[type="range"]::-moz-range-thumb {
  opacity: 0;
}
input[type="range"]::-ms-thumb {
  opacity: 0;
}